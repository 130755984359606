<template>
  <div class="text-light-gray flex min-h-screen flex-col items-center py-14">
    <LogoSvg class="block !h-auto !w-[140px] lg:!w-[200px]" filled />
    <div class="bg-surface-200 my-auto self-stretch rounded-lg p-10">
      <p class="font-headline mb-11 text-center text-4xl uppercase text-white">
        Sign in
      </p>
      <FormKit type="form" submit-label="Login" @submit="submit">
        <div class="mb-5 space-y-6">
          <FormKit
            v-model="state.email"
            type="email"
            name="email"
            label="Email address"
            :errors="getErrorMessages(v$.email.$errors)"
          />
          <FormKit
            v-model="state.password"
            type="password"
            name="password"
            label="Password"
            :errors="getErrorMessages(v$.password.$errors)"
          />

          <p v-if="error" class="text-red mb-5 text-xs">
            {{ error }}
          </p>
        </div>

        <template #submit>
          <AppButton appearance="primary" class="mb-5 w-full" @click="submit">
            Login
          </AppButton>
        </template>
      </FormKit>

      <div class="mb-3 flex w-full items-center">
        <div class="bg-surface-50 h-px grow" />
        <div class="text-surface-50 px-2 text-sm">
          or
        </div>
        <div class="bg-surface-50 h-px grow" />
      </div>

      <AppButton appearance="default" class="mb-5 w-full" @click="navigateTo('/auth/google', { external: true })">
        <span class="inline"><NuxtImg class="mr-1 inline-block size-5" src="/images/google.svg" />Continue with Google</span>
      </AppButton>

      <div class="flex justify-between">
        <p class="text-dark-gray">
          <NuxtLink :to="{ name: 'forgot-password' }">
            Reset Password
          </NuxtLink>
        </p>
        <p>
          No account yet? <NuxtLink class="text-blue underline" :to="{ name: 'sign-up' }">
            Sign up
          </NuxtLink>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { FetchError } from 'ofetch'
import LogoSvg from '@core/assets/images/logo.svg'
import { REFRESH_TOKEN_MAX_AGE } from '../constants'

definePageMeta({
  layout: 'fullscreen'
})

const error = ref<string | null>(null)
const refreshToken = useStatefulCookie<string>('refreshToken', { maxAge: REFRESH_TOKEN_MAX_AGE })
const accessToken = useStatefulCookie<string>('accessToken')
const user = useUserStore()

const state = reactive({
  email: null,
  password: null
})

const { authClient } = useAuthProxyClient()

const v$ = useVuelidate({
  email: {
    required,
    email
  },
  password: {
    required
  }
}, state)

async function submit () {
  error.value = null
  const valid = await v$.value.$validate()

  if (!valid) {
    return
  }

  try {
    const data = await authClient('auth/login', { method: 'POST', body: state })

    refreshToken.value = data.tokens.refresh.token
    accessToken.value = data.tokens.access.token
    user.state = data

    nextTick(() => {
      navigateTo('/')
    })
  } catch (e) {
    if (e instanceof FetchError) {
      if (e.data?.message) {
        error.value = e.data.message
        return
      }

      // @ts-expect-error
      error.value = e.cause?.message ?? 'Failed to fetch'
    }
  }
}

</script>
